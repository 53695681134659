<template>
  <v-runtime-template
    :template="template"
    :class="{ disabled: hidden }"
  ></v-runtime-template>
</template>

<script>
import { AutocompleteItem } from '@mezereon/ui-components-vue'

export default {
  mixins: [AutocompleteItem]
}
</script>
