import Vue from 'vue'
import { isEmpty } from '@/helpers'
import dayjs from 'dayjs'

Vue.filter('tz.format', function (value, format) {
  if (value === null) {
    return null
  }
  return dayjs(value).format(format)
})

Vue.filter('value', function (value) {
  if (isEmpty(value)) {
    return ''
  }
  const match = value.match(/(.*)=>(.*)/i)
  if (match === null) {
    return value
  }
  return match[2]
})

Vue.filter('striphtml', function (value) {
  const replaced = value.replace(
    /<span class="mz-highlight">(.*?)<\/span>/gi,
    '{{mz_start}}$1{{mz_end}}'
  )
  const div = document.createElement('div')
  div.innerHTML = replaced
  const withoutHtml = div.textContent || div.innerText || ''
  const final = withoutHtml.replace(
    /{{mz_start}}(.*?){{mz_end}}/gi,
    '<span class="mz-highlight">$1</span>'
  )
  return final
})
