<template>
  <div v-cloak class="mz-table-row card">
    <slot>
      <article class="mz-table-item product">
        <figure class="card-figure">
          <div
            v-if="isOnSale && context.productSaleBadges"
            class="sale-flag-sash"
          >
            <span class="sale-text">On Sale!</span>
          </div>
          <div>
            <a
              :href="item.custom_url.url"
              @click="trackEvent(item, index, 'click')"
            >
              <img
                v-lazy="{
                  src: item.image,
                  error: 'https://via.placeholder.com/100/eee'
                }"
              />
            </a>
          </div>
          <figcaption class="card-figcaption">
            <div class="card-figcaption-body">
              <a
                v-if="context.showProductQuickView"
                class="button button--small card-figcaption-button quickview"
                :data-product-id="item.id"
              >
                {{ context.langQuickView }}
              </a>
            </div>
          </figcaption>
        </figure>
      </article>
      <div class="mz-table-item second card-body product">
        <span
          v-for="category in item.categories"
          :key="category"
          style="
            font-size: 12px;
            display: block;
            float: right;
            background-color: #dbebf2;
            border-radius: 5px;
            padding: 1px 3px 1px 3px;
            margin: 2px;
          "
        >
          {{ category | value }}
        </span>
        <p class="card-text" data-test-info-type="productRating">
          <span class="rating--small">
            <rating :rating="item.reviews_rating_sum"></rating>
          </span>
        </p>
        <h4 class="card-title">
          <a
            :href="item.custom_url.url"
            @click="trackEvent(item, index, 'click')"
          >
            <span v-html="item.name"></span>
          </a>
        </h4>
        <p class="card-text" data-test-info-type="brandName">
          {{ item.brand | value }}
        </p>
        <p
          v-line-clamp:20="3"
          style="font-size: 1rem; margin-top: 5px"
          v-html="this.$options.filters.striphtml(item.description)"
        ></p>
        <div
          v-if="item.retail_price"
          class="price-section price-section--withoutTax rrp-price--withoutTax price-section--minor"
        >
          {{ context.pdpRetailPriceLabel }}
          <span data-product-rrp-price-without-tax class="price price--rrp">
            {{ item.retail_price | currency }}
          </span>
        </div>
        <div
          v-if="isOnSale"
          class="price-section price-section--withoutTax non-sale-price--withoutTax"
          data-test-info-type="price"
        >
          Was:
          <span
            data-product-non-sale-price-without-tax
            class="price price--non-sale"
          >
            {{ item.price | currency }}
          </span>
        </div>
        <div class="price-section price-section--withoutTax">
          <span v-if="isOnSale" class="price-now-label">Now: </span>
          <span data-product-price-without-tax class="price price--withoutTax">
            {{ item.calculated_price | currency }}
          </span>
        </div>
        <div class="form-action">
          <template v-if="context.customer || !context.restrictToLogin">
            <a
              v-if="item.option_set_id"
              :href="item.custom_url.url"
              data-event-type="product-click"
              class="button button--primary"
              :data-product-id="item.id"
            >
              {{ context.langChooseOptions }}
            </a>
            <a
              v-else
              :href="'/cart.php?action=add&amp;product_id=' + item.id"
              data-event-type="product-click"
              class="button button--primary"
            >
              {{ context.langAddToCart }}
            </a>
          </template>
          <label
            v-if="context.showCompare"
            class="button"
            :for="'compare-' + item.id"
          >
            Compare
            <input
              :id="'compare-' + item.id"
              type="checkbox"
              name="products[]"
              class="compare"
              :value="item.id"
              :data-compare-id="item.id"
            />
          </label>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Rating from './Rating.vue'
import { Hits } from '@mezereon/ui-components-vue'

export default {
  components: {
    Rating
  },
  mixins: [Hits],
  props: {
    item: {
      type: Object,
      required: true
    },
    score: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    isOnSale() {
      return this.item.price !== this.item.calculated_price
    }
  }
}
</script>
