import { mapState } from 'vuex'
import mz from '@mezereon/tracking'
import { setCustomCSS } from '@/helpers'

export default {
  data() {
    return {
      loading: true,
      apiUrl: mz.config.search.url,
      searchKey: mz.config.search.key
    }
  },
  computed: {
    ...mapState('search', ['config', 'primaryKey', 'fallbackMode'])
  },
  created() {
    const store = this.$store

    store.dispatch('api/setApiUrl', this.apiUrl, { root: true })
    store.dispatch('api/setSearchKey', this.searchKey, { root: true })

    if (this.$bus) this.$bus.on('run-autocomplete', this.runAutocomplete)
  },
  async mounted() {
    const store = this.$store

    if (this.fallbackMode) {
      this.loading = false
      return
    }
    await store.dispatch('search/loadConfig', {}, { root: true })
    if (this.fallbackMode) {
      this.loading = false
      return
    } else {
      setCustomCSS('autocomplete.css', this.config.css)
    }
  },
  methods: {
    runAutocomplete(keyword, skipSuggestions) {
      const query = {
        keyword,
        page: 1,
        pageSize: 10,
        sort: '_score',
        includeBanners: false
      }
      const autocompleteConfig = {
        currentComplete: this.complete,
        currentSuggestion: this.currentSuggestion,
        includeSuggestResult: !skipSuggestions,
        includeSearchResult: true
      }
      const context = {
        visitId: mz ? mz.getVisitId() : '',
        visitorId: mz ? mz.getVisitorId() : ''
      }
      const autocomplete = {
        query,
        autocompleteConfig,
        context
      }
      this.$store.dispatch('search/runAutocomplete', autocomplete, {
        root: true
      })
    }
  }
}
