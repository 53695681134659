export default {
  props: {
    filter: {
      type: String,
      required: false,
      default: ''
    },
    pageUrl: {
      type: String,
      required: false,
      default: ''
    },
    categoryId: {
      type: String,
      required: false,
      default: ''
    },
    compareUrl: {
      type: String,
      required: false,
      default: '/compare/'
    },
    showWishlist: {
      type: Boolean,
      required: false,
      default: true
    },
    showCompare: {
      type: Number,
      required: false,
      default: 1
    },
    showProductQuickView: {
      type: Boolean,
      required: false,
      default: true
    },
    restrictToLogin: {
      type: Boolean,
      required: false,
      default: false
    },
    customer: {
      type: Boolean,
      required: false,
      default: false
    },
    productSaleBadges: {
      type: String,
      required: false,
      default: 'none'
    },
    langQuickView: {
      type: String,
      required: false,
      default: 'Quick view'
    },
    langCompare: {
      type: String,
      required: false,
      default: 'Compare'
    },
    langChooseOptions: {
      type: String,
      required: false,
      default: 'Choose Options'
    },
    langPreOrder: {
      type: String,
      required: false,
      default: 'Pre-Order Now'
    },
    langAddToCart: {
      type: String,
      required: false,
      default: 'Add to Cart'
    },
    pdpPriceLabel: {
      type: String,
      required: false,
      default: ''
    },
    pdpRetailPriceLabel: {
      type: String,
      required: false,
      default: 'MSRP:'
    },
    pdpNonSalePriceLabel: {
      type: String,
      required: false,
      default: 'Was:'
    },
    pdpSalePriceLabel: {
      type: String,
      required: false,
      default: 'Now:'
    },
    tags: {
      type: Array,
      required: false,
      default: null
    },
    narrow: {
      type: Object,
      required: false,
      default: null
    },
    custom: {
      type: [Object, Array, String, Number],
      required: false,
      default: null
    }
  },
  computed: {
    context() {
      return {
        filter: this.filter,
        pageUrl: this.pageUrl,
        categoryId: this.categoryId,
        showCompare: this.showCompare,
        showWishlist: this.showWishlist,
        compareUrl: this.compareUrl,
        showProductQuickView: this.showProductQuickView,
        restrictToLogin: this.restrictToLogin,
        customer: this.customer,
        productSaleBadges: this.productSaleBadges,
        pdpPriceLabel: this.pdpPriceLabel,
        pdpRetailPriceLabel: this.pdpRetailPriceLabel,
        pdpNonSalePriceLabel: this.pdpNonSalePriceLabel,
        pdpSalePriceLabel: this.pdpSalePriceLabel,
        langQuickView: this.langQuickView,
        langCompare: this.langCompare,
        langChooseOptions: this.langChooseOptions,
        langPreOrder: this.langPreOrder,
        langAddToCart: this.langAddToCart,
        tags: this.tags,
        narrow: this.narrow,
        custom: this.custom
      }
    }
  },
  created() {
    this.$store.dispatch('search/setContext', this.context, { root: true })
  },
  render() {
    return null
  }
}
